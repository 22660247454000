
import React from "react";
import headshot from "../assets/contactPhotos/kamran_asghar.jpg";

export default {
    left: true,
    title: "Los Angeles",
    address: [
      "Crossmedia Los Angeles",
      "Los Angeles, CA",
      "USA"
    ],
    map: "https://goo.gl/maps/egAvars2EBGSrLDw8",
    contact: {
      partners: [
        {
          title: "Managing Director",
          name: "Kamran Asghar",
          email: "Kamran.asghar@xmedia.com",
          headshot: headshot,
        },
      ],
      website: "www.xmedia.com",
      telephone: "+1 310 954 9009"

    },
    image: "BG-LOSANGELES",
    body: props => (
      <div>
        <p>
        The city of Angels has been home to our team of sun-drenched Crossmedians since launching in 2014. The team provides  top-notch, deep analytical media planning to their expanding client base while maintaining a chilled vibe that defines Southern California living.
        </p>
      </div>
    )
  };