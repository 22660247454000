import React from "react";
import headshot from "../assets/contactPhotos/markus_biermann.jpg";

export default {
  left: false,
  title: "Düsseldorf",
  address: [
    "Crossmedia Düsseldorf",
    "Hildebrandtstr. 24A",
    "40215 Düsseldorf",
    "Germany",
  ],
  map: "https://goo.gl/maps/vBkLfz4Vqek",
  contact: {
    partners: [
      {
        title: "Managing Director",
        name: "Markus Biermann",
        email: "biermann@crossmedia.de",
        headshot: headshot,
      },
    ],
    website: "www.crossmedia.de",
    telephone: "+49 (0)211 866520",
  },
  image: "BG-DÜSSELDORF",
  body: (props) => (
    <div>
      <p>
        The founding location of Crossmedia is hidden in the historic Maoam
        candy factory in the heart of Düsseldorf. The old halls are currently
        home to sharp-minded planners, gifted analysts, creative thinkers, and
        our specialist units that support our German teams in research, BI,
        programmatic, special interest marketing, and tool-based solutions.
      </p>
    </div>
  ),
};
