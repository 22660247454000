import React from "react";
import headshot from "../assets/contactPhotos/tony_williams.jpg";

export default {
  left: false,
  title: "London",
  address: [
    "Crossmedia London",
    "National House",
    "60-66 Wardour Street",
    "3rd Floor",
    "London W1F 0TA",
    "United Kingdom"
  ],
  map: "https://goo.gl/maps/3TzJrCyGn1QNfgteA",
  contact: {
    partners: [
      {
        title: "Managing Director",
        name: "Tony Williams",
        email: "tony.williams@crossmedia.com",
        headshot: headshot,
      },
    ],
    website: "www.crossmedia.com",
    telephone: "+44 20 3746 1414"
  },
  image: "BG-LONDON",
  body: props => (
    <div>
      <p>
      Since September 2017, our Crossmedia London office is based in the heart of Soho. The team has the best of British and Global culture on their doorstep and are primed and ready to showcase their local and international capabilities.
      </p>
    </div>
  )
};
