import React from "react";
import headshot2 from "../assets/contactPhotos/sven_bueteroewe.jpg";

export default {
  left: true,
  title: "Bielefeld",
  address: [
    "Crossmedia Bielefeld",
    "Nikolaus-Dürkopp-Str. 2D",
    "33602 Bielefeld",
    "Germany",
  ],
  map: "https://goo.gl/maps/48FtYJt2Q9vstpao7",
  contact: {
    partners: [
      {
        title: "Managing Director",
        name: "Sven Büteröwe",
        email: "bueteroewe@crossmedia.de",
        headshot: headshot2,
      },
    ],
    website: "www.crossmedia.de",
    telephone: "+49 (0)521 8006840",
  },
  image: "BG-BIELEFELD",
  body: (props) => (
    <div>
      <p>
        Having launched in 2018, our location in Bielefeld's historic center is
        the newest Crossmedia office. Our growing team of young and experienced
        experts deliver integrated and individual solutions for our clients from
        varying industries.
      </p>
    </div>
  ),
};
