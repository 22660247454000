import React from "react";
import headshot from "../assets/contactPhotos/gero_maskow.jpg";

export default {
  left: false,
  title: "Berlin",
  address: [
  "CROSSMEDIA BERLIN",
  "BERLIN",
  "Germany"
],
  map: "https://goo.gl/maps/mHcD19HaJCw",
  contact: {
    partners: [
      {
        title: "Managing Director",
        name: "Gero Maskow",
        email: "maskow@crossmedia.de",
        headshot: headshot,
      },
    ],
    website: "www.crossmedia.de",
    telephone: "+49 (0)30 61657080",
  },
  image: "BG-BERLIN",

  body: (props) => (
    <div>
      <p>
        Based in the German capital and arguably the country’s hippest city, our
        team has a broad mix of backgrounds, skills and perspectives to make
        them masters of collaboration and customized solutions.
      </p>
    </div>
  ),
};
