import React from "react";

export default {
  title: "Cookies Policy",
  body: props => (
    <section>
      <p > <em>Last updated: 19 June 2018</em></p>
      <h3>INFORMATION ABOUT OUR USE OF COOKIES</h3>
      <ol>
        <li>
          Our website (<a href="https://http://www.crossmedia.com">http://www.crossmedia.com/</a>) (<strong>“Website”</strong>) uses cookies to
          distinguish you from other users of our Website. This helps us to
          provide you with a good experience when you browse our Website and
          also allows us to improve our Website. By continuing to browse our
          Website, you are agreeing to our use of cookies.
        </li>
        <li>
          A cookie is a small file of letters and numbers that we store on your
          browser or the hard drive of your device if you agree. Cookies contain
          information that is transferred to your device's hard drive.
        </li>

        <li>
          We use the following cookies:
          <ol>
            <li>
              <strong>Analytical/performance cookies.</strong> They allow us to recognise and
              count the number of visitors and to see how visitors move around
              our Website when they are using it. This helps us to improve the
              way our Website works, for example, by ensuring that users are
              finding what they are looking for easily.
            </li>
          </ol>
        </li>
        <li>
          <p>You can find more information about the individual cookies we use and
          the purposes for which we use them in the table below:</p>
          <h3>Essential Cookies</h3>
          <p>We do not use ‘Essential Cookies’.</p>
          <h3>Non-Essential Cookies</h3>
          <p>
            These cookies will be offered to you when you use the Website – you
            may accept or decline them depending on whether you are happy for
            them to be deployed onto your computer or not.
          </p>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Cookie Name</th>
                <th scope="col">Purpose of Cookie and Information Collected</th>
                <th scope="col">Cookie Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_ga</td>
                <td>see section 3.1</td>
                <td>1yr</td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>
          You may block cookies by activating the setting on your browser that
          allows you to refuse the setting of all or some cookies.
          Alternatively, you can visit <a href="www.allaboutcookies.org">www.allaboutcookies.org</a> which provides
          general information about cookies and how you can manage cookies on
          your computer. Please note that if you use your browser settings to
          block all cookies (including essential cookies) you may not be able to
          access all or parts of our Website.
        </li>
      </ol>
    </section>
  )
};
