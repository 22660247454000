import React from "react";

export default {
  title: "Privacy Policy",
  body: props => (
    <section>
      <p>
        {" "}
        <em>Last updated 18 June 2018.</em>
      </p>

      <p>
        Crossmedia Worldwide Limited, a company registered in England and Wales
        with company number 10768869 and having its registered office at 6th
        Floor Charlotte Building, 17 Gresse Street, London, United Kingdom, W1T
        1QL (<strong>“us”, “our”, “we”, “Crossmedia”</strong>) are committed to
        protecting and respecting your privacy.
      </p>

      <p>
        This privacy policy (the <strong>“Privacy Policy”</strong>) sets out the
        types of personal data we collect and use when you access and visit{" "}
        <a href="https://crossmedia.com">http://www.crossmedia.com/</a> (the{" "}
        <strong>“Website”</strong>) and how we may use that data.
      </p>

      <h4 id="PP-Introduction">1. Introduction</h4>

      <p>
        This Privacy Policy covers our collection, processing and use of
        personal data when you use any of our Website.
      </p>

      <p>
        When you supply any personal data to us we have legal obligations
        towards you in the way we use that data. For ease of reading, we have
        divided this Privacy Policy into several sections:
      </p>

      <ol>
        <li>
          <a href="#PP-Introduction">Introduction </a>
        </li>
        <li>
          <a href="#PP-DataCollection">
            What is personal data and what do we collect?
          </a>
        </li>
        <li>
          <a href="#PP-PersonalData">
            How and why do we use/share your personal data?
          </a>
        </li>
        <li>
          <a href="#PP-StorageDuration">
            For how long do we keep your personal data?
          </a>
        </li>
        <li>
          <a href="#PP-Security">Security </a>
        </li>
        <li>
          <a href="#PP-YourRights">Your Rights </a>
        </li>
        <li>
          <a href="#PP-Contact">Contact Details </a>{" "}
        </li>
      </ol>

      <p>
        It is important that you read this Privacy Policy together with any
        other privacy notice or fair processing notices that we may provide on
        the Website at or around the time that we collect or process personal
        data about you (for example, fair processing notices that we may display
        to you at the time that you sign up to receive e-mail updates from us)
        so that you are fully aware of how and why we are using that data.{" "}
      </p>

      <p>
        This Privacy Policy supplements other notices including our{" "}
        <a
          href=""
          onClick={event => {
            event.preventDefault();
            window.openPage("WebsiteTerms");
          }}
        >
          Website Terms
        </a>{" "}
        and Conditions and our{" "}
        <a
          href=""
          onClick={event => {
            event.preventDefault();
            window.openPage("CookiesPolicy ");
          }}
        >
          Cookies Policy
        </a>{" "}
        and is not intended to override or replace them.
      </p>

      <p>
        By visiting or otherwise using our Website, you are agreeing to the
        practices set out in this Privacy Policy. If, for any reason, you do not
        agree to the terms of this Privacy Policy, please stop using this
        Website.
      </p>

      <p>
        We reserve the right to revise or amend this Privacy Policy at any time
        to reflect changes to our business or changes in the law. Where these
        changes are significant we will endeavour to let users of the Website
        know. However, it is your responsibility to check this Privacy Policy
        before each use of the Website – for ease of reference the top of this
        Privacy Policy indicates the date on which it was last updated.
      </p>

      <p>
        Please note that our Website is not directed at children under the age
        of 13 (each <strong>"Child"</strong> together <strong>"Children"</strong>) and we do not knowingly collect
        personal data about Children.
      </p>

      <h4 id="PP-DataCollection">
        2. What is Personal Data and what do we collect?
      </h4>

      <em>What is personal data?</em>

      <p>
        Where this Privacy Policy refers to ‘personal data’ it is referring to
        data about you from which you could be identified – such as your name,
        your date of birth, your contact details and even your IP address.{" "}
      </p>

      <p>
        By law all organisations in the UK are obliged to process your personal
        data in certain ways and to ensure that you are given an appropriate
        amount of information about how they use it. You also have various
        rights to seek information from those organisations about how they are
        using your data, and to prevent them from processing it unlawfully. For
        more information about these rights, please see the{" "}
        <a href="#PP-Rights">‘Your Rights’</a>
        section of this Privacy Policy.
      </p>

      <p>Data we collect from you when you use the Website</p>

      <p>Voluntary Provision of Data</p>

      <p>
        When you voluntarily supply your personal data to us, for example where
        you contact us by post, telephone or e-mail; report a problem with a
        Website; we may collect, store and use the personal data that you
        disclose to us.{" "}
      </p>

      <p>
        The personal data we collect from you may include your title, name,
        address, e-mail address, company, job title, phone number – but will
        depend on precisely what details you volunteer to us as you interact
        with the Website.
      </p>

      <em id="PP-AutomatedCollection">Automated Collection of Data</em>
      <p>
        We may also collect personal data about you when you visit the Website
        through the use of technologies such as cookies. The following are
        examples of data we may collect:
      </p>

      <ul>
        <li>information about your device, browser or operating system;</li>
        <li>your IP address;</li>
        <li>
          information about links that you click and pages you view on our
          Website
        </li>
        <li>length of visits to certain pages;</li>
        <li>page response times;</li>
        <li>records of download errors and/or broken links;</li>
        <li>
          page interaction information (such as details of your scrolling,
          clicks, and mouse-overs);
        </li>
        <li>methods used to browse away from the page; and</li>
        <li>
          the full Uniform Resource Locators (URL) clickstream to, through and
          from this Website (including date and time).{" "}
        </li>
      </ul>
      <p>
        We use the data described above for several different reasons. Firstly
        we use it to ensure that the Website works properly and that you are
        able to receive the full benefit of it. Second, we use the data to
        monitor online traffic and audience participation across the Website. We
        undertake both of these activities because we have a legitimate interest
        in doing so.
      </p>

      <em>Third Party Resources</em>

      <p>
        We use the services of third parties (e.g. Google Analytics) to help us
        to collect various data about you and the way that you use the Website
        in order to enable us to better understand your interests.{" "}
      </p>

      <p>
        Where we use cookies to deliver these kind of services, the delivery of
        those cookies will be subject to your consent and you will be given the
        opportunity to refuse those cookies prior to them being delivered to
        your computer. You are also able to remove cookies from your computer at
        any time.
      </p>

      <p>
        For details of the cookies that we use on the Website, what those
        cookies do, and the privacy policies of the third parties which provide
        them (where relevant) please see our{" "}
        <a
          href=""
          onClick={event => {
            event.preventDefault();
            window.openPage("CookiesPolicy ");
          }}
        >
          Cookies Policy
        </a>.
      </p>

      <p>
        Please note that, while you have the option to consent to specific
        individual cookies that, unless specifically set out in our{" "}
        <a
          href=""
          onClick={event => {
            event.preventDefault();
            window.openPage("CookiesPolicy ");
          }}
        >
          Cookies Policy
        </a>, we rely on our legitimate interests as our condition for
        processing your personal data on the basis set out in this section
        (specifically that we have a legitimate interest in optimising the
        Website content and ensuring that you are served with relevant
        commercial communications as you use them).{" "}
      </p>

      <h4 id="PP-PersonalData">
        3. How and why do we use/share your personal data
      </h4>

      <p>Lawful basis and purposes of processing your personal data</p>

      <p>
        The primary purpose for which we collect information about you is to
        enable us to perform our business activities, functions and services and
        to provide customer service effectively. We collect, hold, use and
        disclose your personal information for the following purposes:
      </p>
      <ul>
        <li>to provide products and services to you;</li>
        <li>
          to provide you with information about our existing and new products
          and services (including for direct marketing purposes);
        </li>
        <li>to communicate with you including by email, mail or telephone;</li>
        <li>to manage and enhance our products and services;</li>
        <li>
          to personalise and customise your experience with our products and
          services;
        </li>
        <li>to verify your identity;</li>
        <li>
          to provide as part of business data to third parties if you have
          authorised us to do so;
        </li>
        <li>to investigate any complaints about, or made by, you;</li>
        <li>
          to investigate any suspected breach of any of our terms and conditions
          or unlawful activity engaged in by you; and
        </li>
        <li>as required or permitted by any law.</li>
      </ul>
      <p>
        If you do not provide us with the personal information described in this
        policy:
      </p>
      <ul>
        <li>
          we may not be able to provide you with information about products and
          services that you requested;
        </li>
        <li>
          we may not be able to provide you with the products or services you
          requested;
        </li>
        <li>
          we may be unable to tailor the content of the Website to your
          preferences and your experience of the Website may therefore not be
          useful.{" "}
        </li>
      </ul>
      <p>
        We will only use your personal information when the law allows us to,
        i.e. when it is necessary to:
      </p>
      <ul>
        <li>comply with our legal and regulatory obligations;</li>
        <li>
          pursue our legitimate interests (see{" "}
          <a href="#PP-AutomatedCollection">‘Automated Collection of Data’</a>
          for further information) and where your interests and fundamental
          rights do not override these interests.{" "}
        </li>
      </ul>
      <p>
        If you have provided us with your individual contact details (for
        example, your personal email address), we will rely on your consent to
        send you electronic communications such as our newsletters and emails
        with information about our products and/or services. If we hold your
        business contact details (for example, your work email address), we will
        rely on legitimate interests of the business to send you electronic
        communications, but we will always provide you with an option to opt out
        from future communications of this kind.
      </p>

      <em>Sharing your personal data</em>

      <p>
        Depending on how and why you provide us with your personal data we may
        share it in the following ways:
      </p>

      <ul>
        <li>
          we may share your personal data with any member of our company group,
          which means our subsidiaries, our ultimate holding company and its
          subsidiaries, as defined in section 1159 of the UK Companies Act 2006;{" "}
        </li>
        <li>
          with selected third parties who we sub-contract to provide various
          services and/or aspects of the Website’s functionality (see{" "}
          <a href="PP-ServiceProviders">“Service Providers”</a> below); and{" "}
        </li>
        <li>
          with analytics and search engine providers that assist us in the
          improvement and optimisation of this Website as described above.
        </li>
      </ul>

      <p>
        We may also disclose your personal data to third parties in the
        following events:{" "}
      </p>

      <ul>
        <li>
          if we were to sell or buy any business or assets, in which case we
          might disclose your personal data to the prospective seller or buyer
          of such business or assets as part of that sale;{" "}
        </li>
        <li>
          if Crossmedia or substantially all of its assets are acquired by a
          third party, in which case personal data held by us about our
          customers will be one of the transferred assets;{" "}
        </li>
        <li>
          if we are under a duty to disclose or share your personal data in
          order to comply with any legal obligation, or if we are asked to
          provide your details to a lawful authority in order to aid in the
          investigation of crime or disorder; and/or{" "}
        </li>
        <li>
          in order to enforce or apply our Website’s terms of use or terms and
          conditions; or to protect the rights, property, or safety of our
          company, our customers, or others. This includes exchanging
          information with other companies and organisations for the purposes of
          fraud protection and credit risk reduction.
        </li>
      </ul>
      <em id="PP-ServiceProviders">Service Providers</em>

      <p>
        Our service providers provide us with a variety of administrative,
        statistical, and technical services. We will only provide service
        providers with the minimum amount of personal data they need to fulfil
        the services we request, and we stipulate that they protect this data
        and do not use it for any other purpose. We take these relationships
        seriously and oblige all of our data processors to sign contracts with
        us that clearly set out their commitment to respecting individual
        rights, and their commitments to assisting us to help you exercise your
        rights as a data subject. The following is a list of our major service
        providers:
      </p>
      <ul>
        <li>
          Our website is hosted on a third party server, which is located in the
          USA.
        </li>
      </ul>
      <em>Links to third party sites </em>

      <p>
        Where we provide links to third party websites that are not affiliated
        with our Website (or any of our others domains) such sites are out of
        our control and are not covered by this Privacy Policy. If you access
        third party sites using the links provided, the operators of these sites
        may collect personal data from you that could be used by them, in
        accordance with their own privacy policies. Please check these policies
        before you submit any personal data to those websites.{" "}
      </p>

      <h4 id="PP-StorageDuration">
        4. For how long do we keep your personal data?
      </h4>

      <p>
        We will hold your personal data on our systems only for as long as
        required to provide you with the services you have requested or to
        perform the purpose for which that data was collected.
      </p>

      <p>
        Where you sign up to receive e-mail marketing from us we will retain
        your e-mail address on file should you ever ‘opt-out’ of receiving
        e-mails from us. We will retain your e-mail address in this way in order
        to ensure that we continue to honour and respect that opt-out request.
      </p>

      <p>
        In some circumstances you can ask us to delete your data: see ‘Your
        Rights’ below for further information.
      </p>

      <p>
        In some circumstances we may anonymise your personal data (so that it
        can no longer be associated with you) for research or statistical
        purposes, in which case we may use this data indefinitely without
        further notice to you.
      </p>

      <h4 id="PP-Security">5. Security</h4>

      <p>
        We take the protection of your personal data very seriously. We use
        encryption (SSL) to protect your personal data when appropriate, and all
        the data provided to us is stored on secure servers once we receive it.
      </p>

      <p>
        We may store your personal data on secure servers either on our premises
        or in secure third party data centres.{" "}
      </p>

      <p>
        Please note that some of our service providers may be based outside of
        the European Economic Area (the <strong>“EEA”</strong>). These service providers may work
        for us or for one of our suppliers and may be engaged in, among other
        things, the provision of support services. By submitting your personal
        data, you agree to this transfer, storing or processing. Where we
        transfer your data to a service provider that is outside of the EEA we
        seek to ensure that appropriate safeguards are in place to make sure
        that your personal data is held securely and that your rights as a data
        subject are upheld. If you would like more information about how the
        mechanism via which your personal data is transferred, please contact
        <a href="mailto:martin.albrecht@crossmedia.com">
          Martin.Albrecht@crossmedia.com
        </a>.
      </p>

      <h4 id="PP-YourRights">6. Your Rights</h4>

      <p>
        As a data subject you have a number of rights in relation to your
        personal data. Below, we have described the various rights that you
        have, as well as how you can exercise them.
      </p>

      <em>Right of Access</em>

      <p>
        You may, at any time, request access to the personal data that we hold
        which relates to you (you may have heard of this right being described
        as a <strong>"subject access request"</strong>).{" "}
      </p>

      <p>
        Please note that this right entitles you to receive a copy of the
        personal data that we hold about you in order to enable you to check
        that it is correct and to ensure that we are processing that personal
        data lawfully. It is not a right that allows you to request personal
        data about other people, or a right to request specific documents from
        us that do not relate to your personal data.{" "}
      </p>

      <p>
        You can exercise this right at any time by writing to us using the
        contact details set out <a href="#PP-Contact">here</a> and telling us
        that you are making a subject access request. You do not have to fill in
        a specific form to make this kind of request.
      </p>

      <em>Your Right to Rectification and Erasure</em>

      <p>
        You may, at any time, request that we correct personal data that we hold
        about you which you believe is incorrect or inaccurate. You may also ask
        us to erase personal data if you do not believe that we need to continue
        retaining it (you may have heard of this right described as the “right
        to be forgotten”).
      </p>

      <p>
        Please note that we may ask you to verify any new data that you provide
        to us and may take our own steps to check that the new data you have
        supplied us with is right. Further, we are not always obliged to erase
        personal data when asked to do so; if for any reason we believe that we
        have a good legal reason to continue processing personal data that you
        ask us to erase we will tell you what that reason is at the time we
        respond to your request.{" "}
      </p>

      <p>
        You can exercise this right at any time by writing to us using the
        contact details set out here and telling us that you are making a
        request to have your personal data rectified or erased and on what basis
        you are making that request. If you want us to replace inaccurate data
        with new data, you should tell us what that new data is. You do not have
        to fill in a specific form to make this kind of request.
      </p>

      <em>Your Right to Restrict Processing</em>

      <p>
        Where we process your personal data on the basis of a legitimate
        interest (see the sections of this Privacy Policy which explain how and
        why we use your information) you are entitled to ask us to stop
        processing it in that way if you feel that our continuing to do so
        impacts on your fundamental rights and freedoms or if you feel that
        those legitimate interests are not valid.{" "}
      </p>
      <p>
        We may process your data on the basis of a legitimate interest in the
        following ways:
      </p>
      <em>Optimised website content</em>
      <p>
        You may also ask us to stop processing your personal data (a) if you
        dispute the accuracy of that personal data and want us verify that
        data's accuracy; (b) where it has been established that our use of the
        data is unlawful but you do not want us to erase it; (c) where we no
        longer need to process your personal data (and would otherwise dispose
        of it) but you wish for us to continue storing it in order to enable you
        to establish, exercise or defend legal claims.{" "}
      </p>
      <p>
        Please note that if for any reason we believe that we have a good legal
        reason to continue processing personal data that you ask us to stop
        processing, we will tell you what that reason is, either at the time we
        first respond to your request or after we have had the opportunity to
        consider and investigate it.{" "}
      </p>
      <p>
        You can exercise this right at any time by writing to us using the
        contact details set out here and telling us that you are making a
        request to have us stop processing the relevant aspect of your personal
        data and describing which of the above conditions you believe is
        relevant to that request. You do not have to fill in a specific form to
        make this kind of request.
      </p>
      <em>Your Right to Portability</em>
      <p>
        Where you wish to transfer certain personal data that we hold about you,
        which is processed by automated means, to a third party you may write to
        us and ask us to provide it to you in a commonly used machine-readable
        format.{" "}
      </p>
      <p>
        Because of the kind of work that we do and the systems that we use, we
        do not envisage this right being particularly relevant to the majority
        of individuals with whom we interact. However, if you wish to transfer
        your data from us to a third party we are happy to consider such
        requests.
      </p>
      <em>Your Right to stop receiving communications</em>
      <p>
        Where we send you e-mail marketing communications (or other regulated
        electronic messages) you have the right to opt-out at any time. You can
        do this by using the ‘unsubscribe’ link that appears in the footer of
        each communication (or the equivalent mechanism in those
        communications).
      </p>
      <p>
        Alternatively, if for any reason you cannot use those links, or if you
        would prefer to contact us directly – you can unsubscribe by writing to
        us at <a href="mailto:corpcom@crossmedia.de">corpcom@crossmedia.de</a> and telling us which communications you
        would like us to stop sending you.
      </p>
      <em>Your Right to object to automated decision making and profiling</em>
      <p>
        You have the right to be informed about the existence of any automated
        decision making and profiling of your personal data, and where
        appropriate, be provided with meaningful information about the logic
        involved, as well as the significance and the envisaged consequences of
        such processing that affects you.{" "}
      </p>
      <em>Exercising your rights</em>
      <p>
        When you write to us making a request to exercise{" "}
        <a href="#PP-YourRights">your rights</a> we are entitled to ask you to
        prove that you are who you say you are. We may ask you to provide copies
        of relevant ID documents to help us to verify your identity.{" "}
      </p>
      <p>
        It will help us to process your request if you clearly state which right
        you wish to exercise and, where relevant, why it is that you are
        exercising it. The clearer and more specific you can be, the faster and
        more efficiently we can deal with your request. If you do not provide us
        with sufficient information then we may delay actioning your request
        until you have provided us with additional information (and where this
        is the case we will tell you).
      </p>

      <h4 id="PP-Contact">7. Contact Details</h4>
      <p>
        If you have any queries regarding this Privacy Policy, if you wish to
        exercise any of your rights set out above or if you think that the
        Privacy Policy has not been followed, please contact us{" "}
        <a href="mailto:martin.albrecht@crossmedia.com">
          Martin.Albrecht@crossmedia.com
        </a>{" "}
        or at{" "}
      </p>
      <p>
        <div>CROSSMEDIA Worldwide Ltd.</div>
        <div>22 Great Marlborough Street</div>
        <div>Soho, London W1F 7HU</div>
        <div>United Kingdom</div>
      </p>
    </section>
  )
};
