import React from "react";
import headshot from "../assets/contactPhotos/kamran_asghar.jpg";

export default {
  left: true,
  title: "Philadelphia",
  address: [
    "Philadelphia",
    "3 Rector Street",
    "Suite 2",
    "Philadelphia, PA 19127",
    "USA"
  ],
  map: "https://goo.gl/maps/8u7cQmkLcxF2",
  contact: {
    partners: [
      {
        title: "Managing Director",
        name: "Kamran Asghar",
        email: "Kamran.asghar@xmedia.com",
        headshot: headshot,
      },
    ],
    website: "www.xmedia.com",
    telephone: "+1 267 401 1660"
  },
  image: "BG-PHILADELPHIA",
  body: props => (
    <div>
      <p>
        Our outpost in The City of Brotherly Love offers a wealth of history,
        culture, and diversity while delivering an equally rich talent pool that
        is ripe with innately client-focused personalities, can-do attitudes, and
        tech savvy minds.
      </p>
    </div>
  )
};