import "./styles.scss";

import React from "react";
import ReactDOM from "react-dom";

import Menu from "react-burger-menu/lib/menus/push";

// Import Location Data

import Berlin from "./LocationData/Berlin";
import Bielefeld from "./LocationData/Bielefeld";
import Düsseldorf from "./LocationData/Düsseldorf";
import Hamburg from "./LocationData/Hamburg";
import London from "./LocationData/London";
import LosAngeles from "./LocationData/LosAngeles";
import NewYork from "./LocationData/NewYork";
import Philadelphia from "./LocationData/Philadelphia";

import CookiesPolicy from "./pages/cookiesPolicy";
import WebsiteTerms from "./pages/websiteTerms";
import PrivacyPolicy from "./pages/privacyPolicy";

import CrossMapMarker from "./assets/icons/CrossMapMarker";

const Location = props => {
  let data = props.data;

  const Head = () => {
    return (
      <div className={"col-xl Location-Images " + (!data.left ? " left" : "")}>
        <div className={data.image + (!data.left ? " text-right" : "")}>
          <section className="sticky-top location-h text-white">
            <h2 className="display-1 text-white">{data.title}</h2>

            <ul className="list-group list-group-flush ContactHeader">
              {data.address.map(item => {
                return (
                  <li key={item} className="list-group-item">
                    {item}
                  </li>
                );
              })}
            </ul>

            <a
              className={"CrossMapMarkerWrapper" + (data.left ? " left" : "")}
              href={data.map}
              target="_blank"
            >
              <CrossMapMarker />
            </a>
          </section>
        </div>
      </div>
    );
  };

  return (
    <section
      className="row no-gutters Location"
      id={data.title.replace(" ", "")}
    >
      {!data.left ? <Head /> : null}

      <div className={"col-md Body" + (data.left ? " text-right" : "")}>
        <section className="container p-5">
          <div className={data.left ? "text-right" : ""}>
            <h4> {data.title}</h4>
            <data.body />
          </div>
          <div className="mt-5 mb-5">
            <div className="ContactInfo">
              <a target="_blank" href={"https://" + data.contact.website} className="">
                <i className="fas fa-link fa-xs" /> {data.contact.website}
              </a>
              {"    "}
              <a
                href={"tel:" + data.contact.telephone.replace(" ", "")}
                className=""
                style={{ letterSpacing: "0.1rem" }}
              >
                <i className="fas fa-phone fa-xs" /> {data.contact.telephone}
              </a>
            </div>
          </div>

          <div className="row ContactData">
            <div className="col-md-12">

              <div className="row">
                
              {data.contact.partners.map((partner, index) => {
                return (

                <div className={"col " + (data.left ? "text-right" : "")} key={index}>
                  <div className="DirectorPhoto">
                    <img className="" src={partner.headshot} />
                  </div>

                  <div className="">{partner.name} </div>
                  <a
                    href={"mailto:" + partner.email.toLowerCase()}
                    className=""
                  >
                    {partner.email.toLowerCase()}
                  </a>
                </div>

                )})}

              </div>

            </div>
          </div>
        </section>
      </div>

      {data.left ? <Head /> : null}
    </section>
  );
};

const Locations = () => (
  <section className="Locations">
    <Location data={Berlin} />
    <Location data={Bielefeld} />
    <Location data={Düsseldorf} />
    <Location data={Hamburg} />
    <Location data={London} />
    <Location data={LosAngeles} />
    <Location data={NewYork} />
    <Location data={Philadelphia} />
  </section>
);

const initLocations = () => {
  const LocationsElement = document.getElementById("Locations");

  if (LocationsElement.children.length === 0) {
    console.log(
      "Location section element has not been prerendered. Using React render method..."
    );
    ReactDOM.render(<Locations />, LocationsElement);
  } else {
    console.log("Element has been prerendered. Hydrating...");
    ReactDOM.hydrate(<Locations />, LocationsElement);
    console.log("Element has been successfully hydrated.");
  }
};

initLocations();

window.pageIsOpen = false;

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, current: CookiesPolicy };
    // Disgusting.
    window.openPage = this.open.bind(this);

    this.pages = {
      CookiesPolicy: CookiesPolicy,
      WebsiteTerms: WebsiteTerms,
      PrivacyPolicy: PrivacyPolicy
    };
  }

  showSettings(event) {
    //event.preventDefault();
  }

  open(event) {
    console.log(event);
    this.setState({ isOpen: true, current: this.pages[event] });
  }

  isMenuOpen(page) {
    if (page.isOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
    return page.isOpen;
  }

  render() {

    return (
      <Menu
        className="Menu"
        right
        isOpen={this.state.isOpen}
        width={"100vw"}
        pageWrapId={"page-wrap"}
        outerContainerId={"App"}
        onStateChange={this.isMenuOpen}
        //noOverlay
      >
        <div className="row">
          <div className="col-md">
            <section className="sticky-top location-h text-right text-white">
              <h2 className="display-3">{this.state.current.title}</h2>
            </section>
          </div>

          <div className="col-md-8 container p-5" id="PageContainer">
            <this.state.current.body container={"PageContainer"}/>
          </div>
        </div>
      </Menu>
    );
  }
}
//
ReactDOM.render(<Pages />, document.getElementById("SubPages"));

window.linkCallback = event => {
  event.preventDefault();

};

const Links = props => {
  const openPage = event => {
    event.preventDefault();
    window.openPage(event.target.dataset.href);
  };

  return (
    <div>
      <p>
        <a href="" target="_blank" data-href="WebsiteTerms" onClick={openPage}>
          Website Terms
        </a>
      </p>
      <p>
        <a href="" target="_blank" data-href="CookiesPolicy" onClick={openPage}>
          Cookies Policy
        </a>
      </p>
      <p>
        <a href="" target="_blank" data-href="PrivacyPolicy" onClick={openPage}>
          Privacy Policy
        </a>
      </p>
    </div>
  );
};

ReactDOM.render(<Links />, document.getElementById("Links"));
