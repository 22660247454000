import React from "react";
import headshot from "../assets/contactPhotos/kamran_asghar.jpg";

export default {
  left: false,
  title: "New York",
  address: [
    "Crossmedia New York",
    "275 7th Avenue – 27th Floor",
    "New York, New York 10001",
    "USA"
  ],
  map: "https://goo.gl/maps/FFgPheZceRS2",
  contact: {
    partners: [
      {
        title: "Managing Director",
        name: "Kamran Asghar",
        email: "Kamran.asghar@xmedia.com",
        headshot: headshot,
      },
    ],
    website: "www.xmedia.com",
    telephone: "+1 212 206 0888"
  },
  image: "BG-NEWYORK",
  body: props => (
    <div>
      <p>
        New York City is home to the flagship of the XM offering in the US,
        situated in the landmark 275 7th Avenue building and close to multiple
        #burgerday locations where our Friday tradition originated.
      </p>
    </div>
  )
};
