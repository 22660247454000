import React from "react";
import headshot from "../assets/contactPhotos/david_manschwedat.jpg";

export default {
  left: true,
  title: "Hamburg",
  address: ["Crossmedia Hamburg", "Alter Wandrahm 11", "20457 Hamburg", "Germany"],
  map: "https://goo.gl/maps/wQMdr7zctpRKKrDU7",
  contact: {
    partners: [
      {
        title: "Managing Director",
        name: "David Manschwedat",
        email: "manschwedat@crossmedia.de",
        headshot: headshot,
      },
    ],
    website: "www.crossmedia.de",
    telephone: "+49 40 334611 0"
  },
  image: "BG-HAMBURG",
  body: props => (
    <div>
      <p>
        Our team has been based in this northern German city since 2006. Their
        fast service-oriented approach delivers maximum results for their
        clients and they believe in the power of integrated planning to get the
        most out of every channel.
      </p>
    </div>
  )
};