import React from "react";

export default {
  title: "Website Terms",
  body: props => (
    <section>
      <p>
        <em>Terms of Use: Last updated 19 June 2018</em>
      </p>
      <h3>
        THESE TERMS OF USE APPLY TO ALL USE OF THE WEBSITE. BY CONTINUING TO USE
        THE WEBSITE YOU ARE INDICATING YOUR ACCEPTANCE OF THEM. PLEASE READ
        THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE
      </h3>
      <p>
        These terms of use (together with the various documents referred to in
        them) set out the terms of use on which you may make use of our website{" "}
        <a href="https://www.crossmedia.com/">http://www.crossmedia.com/</a>{" "}
        (the “Website”). Use of the Website includes accessing it, perusing it,
        or using any of the functionality offered via it.
      </p>
      <p>
        Please read these terms of use carefully before you start to use the
        Website as they represent a binding legal agreement and you will be
        bound by them. We recommend that you print a copy of the terms for
        future reference.
      </p>
      <p>
        By using the Website, you confirm that you accept these terms of use and
        that you agree to comply with them.
      </p>
      <p>
        If you do not agree to these terms of use, you must not use the Website.
      </p>
      <h4>OTHER APPLICABLE TERMS AND POLICIES</h4>
      <p>
        These terms of use refer to the following additional terms, which also
        apply to your use of the Website:
      </p>
      <ul>
        <li>
          Our Privacy Policy, which sets out the terms on which we process any
          personal data we collect from you, or that you provide to us. By using
          the Website, you consent to such processing and you warrant that all
          data provided by you is accurate.
        </li>
        <li>
          Our Cookie Policy, which sets out information about the cookies on the
          Website.
        </li>
      </ul>
      <h4>INFORMATION ABOUT THE OWNER OF THE WEBSITE</h4>
      <p>
        The Website is operated by Crossmedia Worldwide Limited ("We"). We are
        registered in England and Wales under company number 10768869 and have
        our registered office at 6th Floor Charlotte Building, 17 Gresse Street,
        London, United Kingdom, W1T 1QL.You can contact us by telephoning us as
        +44(0)20.3051.8426 or by writing to us at{" "}
        <a href="mailto:Martin.Albrecht@crossmedia.com.">
          Martin.Albrecht@crossmedia.com
        </a>.
      </p>
      <h4>CHANGES TO THESE TERMS AND CONDITIONS</h4>
      <p>
        Please note that we reserve the right to revise or amend these terms at
        any time to reflect changes to our business or changes in the law. Where
        these changes are significant we will endeavour to e-mail all of our
        registered users to make sure that they are informed of such changes.
      </p>
      <p>
        However, it is your responsibility to check these terms before each use
        of the Website.
      </p>
      <h4>CHANGES AND CONTENT</h4>
      <p>
        We may update the Website from time to time, and may change the content
        featured on it at any time.
      </p>
      <p>
        We do not guarantee that the Website, or any content on it, will be free
        from errors or omissions, nor do we undertake regularly to update the
        Website or to ensure that content on it is up to date or current.
      </p>
      <p>
        We do not offer professional advice of any kind via the Website and you
        should not treat any content featured on it as professional advice of
        any kind, and should always obtain appropriate specialist advice before
        taking, or refraining from, any course of action on the basis of the
        content of the Website.
      </p>
      <h4>ACCESSING THE WEBSITE</h4>
      <p>
        We do not guarantee that the Website, or any content on it, will always
        be available or that access to it will be uninterrupted. Access to the
        Website is permitted on a temporary basis. We may suspend, withdraw,
        discontinue or change all or any part of the Website without notice. We
        will not be liable to you if for any reason the Website is unavailable
        at any time or for any period.
      </p>
      <p>
        ou accept that you are responsible for ensuring that all persons who
        access the Website via internet connections controlled by you are aware
        of these terms of use and other applicable terms and conditions, and
        that they comply with them. You agree that you shall be responsible for
        the actions of all such individuals and that we are not responsible for
        preventing such individuals from accessing the Website via your internet
        connection.
      </p>
      <p>
        The Website is directed at individuals residing in the United Kingdom.
        We do not represent that content available on the Website is appropriate
        or localised for other locations. We may limit the availability of the
        Website or any service or product described on the Website to any person
        or geographic area at any time. If you choose to access the Website from
        outside the United Kingdom, you do so at your own risk and accept that
        you are wholly responsible for your own compliance with local laws.
      </p>
      <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
      <p>
        We are the owner or the licensee of all intellectual property rights
        inherent in the Website, and in the material published on it. Nothing in
        these terms or on the Website shall give effect to any transfer of such
        intellectual property rights from us to you.
      </p>
      <p>
        Your sole right to use the intellectual property inherent in the Website
        is a non-exclusive licence to make use of such content only as is
        strictly necessary to enable you to access the Website and to peruse its
        contents.
      </p>
      <p>
        You may print off one copy, and may download extracts, of any page(s)
        from the Website for your personal use and you may draw the attention of
        others within your organisation to content posted on the Website.
      </p>
      <p>
        You must not modify the paper or digital copies of any materials you
        have printed off or downloaded in any way, and you must not use any
        illustrations, photographs, video or audio sequences or any graphics
        separately from any accompanying text.
      </p>
      <p>
        Our status (and that of any identified contributors) as the authors of
        content on the Website must always be acknowledged.
      </p>
      <p>
        You must not use any part of the content on the Website for commercial
        purposes without obtaining a licence to do so from us or our licensors.
      </p>
      <p>
        If you print off, copy or download any part of the Website in breach of
        these terms of use, your right to use the Website will cease immediately
        and you must, at our option, return or destroy any copies of the
        materials you have made.
      </p>
      <h4>LIMITATION OF OUR LIABILITY</h4>
      <p>
        Nothing in these terms of use excludes or limits our liability for death
        or personal injury arising from our negligence, or our fraud or
        fraudulent misrepresentation, or any other liability that cannot be
        excluded or limited by English law.
      </p>
      <p>
        To the extent permitted by law, we exclude all conditions, warranties,
        representations or other terms which may apply to the Website or any
        content on it, whether express or implied.{" "}
      </p>
      <p>
        We will not be liable to any user for any loss or damage, whether in
        contract, tort (including negligence), breach of statutory duty, or
        otherwise, even if foreseeable, arising under or in connection with:
      </p>
      <ul>
        <li>use of, or inability to use, the Website; or </li>
        <li>use of or reliance on any content displayed on the Website. </li>
        <li>loss of profits, sales, business, or revenue; </li>
        <li>business interruption; </li>
        <li>loss of anticipated savings; </li>
        <li>loss of business opportunity, goodwill or reputation; or </li>
        <li>any indirect or consequential loss or damage. </li>
      </ul>
      <p>
        We will not be liable for any loss or damage caused by a virus,
        distributed denial-of-service attack, or other technologically harmful
        material that may infect your computer equipment, computer programs,
        data or other proprietary material due to your use of the Website or to
        your downloading of any content on it, or on any website linked to it.
      </p>
      <h4>VIRUSES</h4>
      <p>
        We do not guarantee that the Website will be secure or free from bugs or
        viruses.
      </p>
      <p>
        You are responsible for configuring your information technology,
        computer programmes and platform in order to access the Website. You
        should use your own virus protection software.
      </p>
      <p>
        You must not misuse the Website by knowingly introducing viruses,
        trojans, worms, logic bombs or other material which is malicious or
        technologically harmful. You must not attempt to gain unauthorised
        access to the Website, the server on which the Website is stored or any
        server, computer or database connected to the Website. You must not
        attack the Website via a denial-of-service attack or a distributed
        denial-of service attack. We will report any such breach to the relevant
        law enforcement authorities and we will co-operate with those
        authorities by disclosing your identity to them. In the event of such a
        breach, your right to use the Website will cease immediately.
      </p>
      <h4>LINKING TO THE WEBSITE</h4>
      <p>
        You may link to our home page, provided you do so in a way that is fair
        and legal and does not damage our reputation or take advantage of it.
      </p>
      <p>
        You must not establish a link in such a way as to suggest any form of
        association, approval or endorsement on our part where none exists.
      </p>
      <p>
        You must not establish a link to the Website in any website that is not
        owned by you.
      </p>
      <p>
        The Website must not be framed on any other site, nor may you create a
        link to any part of the Website other than the home page.
      </p>
      <p>We reserve the right to withdraw linking permission without notice.</p>
      <h4>THIRD PARTY WEBSITE LINKS AND RESOURCES IN THE WEBSITE</h4>
      <p>
        Where the Website contains links to other sites and resources provided
        by third parties, these links are provided for your information only.
      </p>
      <p>We have no control over the contents of those sites or resources.</p>
      <p>
        We assume no responsibility for the content of websites linked on the
        Website. Such links should not be interpreted as endorsement by us of
        those linked websites. We will not be liable for any loss or damage that
        may arise from your use of them.
      </p>
      <p>
        If you do link from the Website to other websites, your use thereof will
        also be subject to those websites’ terms and conditions.
      </p>
      <h4>APPLICABLE LAW</h4>
      <p>
        If you are a consumer, please note that these terms of use, and their
        subject matter are governed by English law. You and we both agree that
        the courts of England and Wales will have non-exclusive jurisdiction
        over any disputes. However, if you are a resident of Northern Ireland
        you may also bring proceedings in Northern Ireland, and if you are
        resident of Scotland, you may also bring proceedings in Scotland.
      </p>
      <p>
        If you are a business, these terms of use and their subject matter (and
        any non-contractual disputes or claims) are governed by English law. We
        both agree to the exclusive jurisdiction of the courts of England and
        Wales.
      </p>
      <h4>CONTACT US</h4>
      <p>
        To contact us, please email{" "}
        <a href="mailto:Martin.Albrecht@crossmedia.com">
          Martin.Albrecht@crossmedia.com
        </a>.
      </p>
    </section>
  )
};
